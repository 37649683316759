// TODO make this more generic (may be once we have some prefix for dashboard URLs)
// Adding URLs one by one will get very unmanageable

export const UNAUTHORIZED_ONLY_ENDPOINTS = [
  '/',
  '/login*',
  '/login/**',
  '/register*',
  '/register/**',
  '/book-trial-class*',
  '/lp/learn-coding*',
]

export const AUTHORIZED_ONLY_ENDPOINTS = [
  '/batches/**',
  '/dashboard/**',
  '/student/**',
  '/teacher/**',
  '/trial-class/**',
  '/feedback/**',
  '/competitions/**/submit*',
  '/competitions/**/**/complete/**',
  '/competitions/**/view-submission*',
  '/competitions/**/problems*',
  '/flipkart-promo/buy-course/**',
  '/fampay-promo/checkout/**',
  '/flipkart-bundle/checkout*',
  '/summer-camp/checkout/**',
  '/gpay-promo/checkout/**',
  '/phonepe-promo/checkout/**',
  '/competition/**/problems/**',
  '/lp/learn-coding/thank-you*',
  '/competitions/**/invite/**',
]

export const GENERAL_HELLO_BAR_EXCLUSION_GLOBS = [
  '/trial-class/**',
  '/dashboard/**',
  '/dashboard*',
  '/payment-status*',
  '/payment/**',
  '/competitions/**/problems/**',
  '/competitions/**/problems',
  '/competitions/**/competition-leaderboard',
  '/competitions/**/submissions',
]
export const UPDATE_INFO_HELLO_BAR_ENDPOINTS = [
  '/competitions',
  '/competitions/*',
  '/dashboard/student/**',
  '/trial-class/complete',
  '/trial-class/incomplete',
  '/quizzes/*',
  '/quizzes',
]

export const TEACHER_DASHBOARD_ROOT_URL = `/teacher/dashboard`

export const TEACHER_DASHBOARD_ROUTES = [
  { title: 'Dashboard', icon: 'Clipboard', url: '' },
  { title: 'Calendar', icon: 'Calendar', url: '/calendar' },
  { title: 'Projects', icon: 'Box', url: '/projects' },
  { title: 'Leaderboard', icon: 'Award', url: '/leaderboard' },
  { title: 'My students', icon: 'Users', url: '/my-students' },
  { title: 'Refer teachers', icon: 'ReferTeachers', url: '/invite-teachers' },
  { title: 'Important links', icon: 'Link', url: '/important-links' },
]

export const STUDENT_DASHBOARD_ROOT_URL = `/student/dashboard`
export const STUDENT_DASHBOARD_ROUTES = [
  { title: 'Dashboard', icon: 'Clipboard', url: '' },
  { title: 'My lessons', icon: 'BookOpen', url: '/modules' },
  { title: 'My projects', icon: 'Box', url: '/projects' },
  { title: 'My quizzes', icon: 'Quiz', url: '/quizzes' },
  { title: 'My certificates', icon: 'Certificate', url: '/certificates' },
  { title: 'Leaderboard', icon: 'Award', url: '/leaderboard' },
  { title: 'Invite friends', icon: 'UserCheck', url: '/invite-friends' },
  { title: 'Competitions', icon: 'Trophy', url: '/competitions' },
  // { title: 'Profile', icon: 'User', url: '/profile' },
]

export const EVENT_TYPES = {
  AVAILABLE_SLOT: 'Available slot',
  BOOKED_SLOT: 'Booked slot',
} as const

export const MIN_CANCELLATION_WINDOW_IN_HOURS = 0
export const CLASSROOM_GLOB = '/batches/**'
export const REGISTER_REF = 'register'
export const LOGIN_REF = 'login'
export const COMPETITIONS_REF = 'competitions'
export const BOOK_TRIAL_CLASS_REF = 'book-trial-class'
export const TEACHER_CAREERS_PATH =
  'https://codingal.freshteam.com/jobs/--hp7mLPXO3v/coding-teacher-remote'

// Keeping it here as we may need it later
// export const LOGROCKET_UNINITIALIZED_PAGES = ['/terms', '/privacy-policy']

export const VIDEO_PROFILES: Record<string, any> = {
  '180x15': '180p_1',
  '360x15': '360p_1',
  '480x15': '480p_1',
  '480x30': '480p_2',
  '720x15': '720p_1',
  '720x30': '720p_2',
  '1080x15': '1080p_1',
  '1080x30': '1080p_2',
}

export const FRESHCHAT_EXCLUSION_GLOB = [
  '/batches/**',
  '/book-trial-class*',
  '/competitions/**/problems/**',
  '/feedback/**',
  '/quizzes/**/arena/**',
  '/lp/**',
  '/benefits-of-coding*',
  '/student/dashboard/**',
  '/competitions/**/register/complete/**',
]

export const LEADSQUARED_EXCLUSION_GLOBS = ['/**/dashboard/**']
export const LOCALE_REDIRECTION_EXCLUSION_GLOBS = [
  '/book-trial-class*',
  '/lp/**',
  '/benefits-of-coding*',
  '/',
]

export const INSTANT_CLASS_MODAL_GLOB = ['/feedback/**', '/batches/**']

export const SUPPORT_PHONE_NUMBER = {
  label: '+91 6361 158952',
  value: '+916361158952',
}
export const NO_SLOTS_AVAILABLE_MESSAGE =
  "Hi Codingal Team, Please assist me in booking a trial coding class. I don't see any slots available right now."

export const SUPPORT_EMAIL = {
  label: 'support@codingal.com',
  value: 'support@codingal.com',
}

export const AVATAR_CROP_MODAL_ID = 'avatar-crop-modal'
export const VIDEO_MODAL_ID = 'watch-video-modal'
export const MOD_LOGIN_MODAL_ID = 'mod-login-modal'
export const PASSWORD_RESET_MODAL_ID = 'password-reset-modal'
export const SWAL_CLASSES = 'swal-overlay swal-overlay--show-modal'

export const CLASS_COMPLETED_SOURCE_QUERY = {
  DASHBOARD: 'dashboard',
  COMPLETED_CLASSES: 'completed-classes',
}

export const MIN_INSTANT_TEACHER_AVAILABILITY_THRESHOLD = 3

export const SIGNUP_SOURCE_LOCAL_STORAGE_KEY = 'codingalSignupSource'

export const SYSTEM_CHECK_RESULT_LOCAL_STORAGE_KEY = 'codingalSystemCheckResult'

export const JOIN_TRIAL_CLASS_STATES = {
  DEVICE_NOT_SUPPORTED: 'device-not-supported',
  SYSTEM_CHECK: 'system-check',
  JOIN_CLASS: 'join-class',
  LOADING: 'loading',
}

export const REQUIRED_THIRD_PARTY_ORIGINS = ['https://support.codingal.com']
export const PREFETCH_URLS = [
  'https://fastly.codingal.com',
  'https://support.codingal.com',
  'https://www.googletagmanager.com	',
  'https://connect.facebook.net	',
  'https://web-in21.mxradon.com',
  'https://js.hscollectedforms.net',
  'https://js.hs-banner.com',
  'https://www.google-analytics.com	',
]

export const TRIAL_CLASS_BANNER_REDIRECT_LINK = {
  LOGGED_OUT: '/register',
  LOGGED_IN: '/trial-class/booking',
}

export const CODINGAL_COMMUNITY_STRENGTH = Number(500000).toLocaleString()

export const DEFAULT_USER_DETAILS = {
  name: 'Student',
  emailDomain: '@codingal-student.com',
}

export const EXOTEL_CALLER_ID = '079-491-08561'
export const TWILLIO_CALLER_ID = '(218) 520-8003'

export const WATI_ACCOUNT_TYPE = {
  default: 'Default',
  paid: 'PAID',
}

export const ZOKO_TEMPLATE_TYPE = {
  TEMPLATE: 'template',
  RICH_TEMPLATE: 'richTemplate',
  BUTTON_TEMPLATE: 'buttonTemplate',
}

export const DEMO_TEACHER_EMAIL = 'aadishmahadik23@gmail.com'

export const TERMS_AND_POLICY_PATHS = {
  'TERMS OF SERVICE': '/terms/',
  'PRIVACY POLICY': '/privacy-policy/',
  'REFUND POLICY': '/refund-policy/',
  'PAYMENT POLICY': '/payment-policy/',
}

// Default values for the responsive carousel (react-multi-carousel)
export const responsive = {
  superLargeDesktop: {
    breakpoint: { max: 4000, min: 1288 },
    items: 4,
    slidesToSlide: 4, // optional, default to 1.
  },
  desktop: {
    breakpoint: { max: 1287, min: 1024 },
    items: 3,
    slidesToSlide: 3, // optional, default to 1.
  },
  tablet: {
    breakpoint: { max: 1023, min: 768 },
    items: 2,
    slidesToSlide: 2, // optional, default to 1.
  },
  mobile: {
    breakpoint: { max: 767, min: 200 },
    items: 1,
    slidesToSlide: 1, // optional, default to 1.
  },
}
